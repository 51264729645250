import React from "react";

const Home = React.lazy(() => import("./components/home"));
const Personal = React.lazy(() => import("./components/personal"));
const MediumBlogs = React.lazy(() => import("./components/medium-blogs"));
const BlogDetail = React.lazy(() => import("./components/blog-detail"));
const MediumBlogDetail = React.lazy(
  () => import("./components/medium-blog-detail")
);

const routes = [
  {
    path: "/",
    element: Home,
  },
  {
    path: "/personal",
    element: Personal,
  },
  {
    path: "/medium-blogs",
    element: MediumBlogs,
  },
  {
    path: "/blogs/:fileName",
    element: BlogDetail,
  },
  {
    path: "/medium-blogs/:fileName",
    element: MediumBlogDetail,
  },
  {
    path: "*",
    element: Home,
  },
];

export default routes;
